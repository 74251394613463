import { ProductsResponse, MainCategoryResponse, SubCategoryResponse, MainCategory, SubCategory, Product } from '@/types';
import IdbApi from './idb-api';

export default class CockpitApi {
    private readonly baseUrl: string = process.env.VUE_APP_COCKPIT_URL + '/api';
    private readonly token: string = process.env.VUE_APP_COCKPIT_TOKEN || '';
    private idbApi: IdbApi = new IdbApi();

    public async getProducts(): Promise<Product[]> {
        const idbapi = new IdbApi();
        const response = await fetch(
            `${this.baseUrl}/collections/get/products`,
            {
                headers: { 'Cockpit-Token': this.token },
            }
        );

        const data = await response.json() as Promise<ProductsResponse>;
        let doneData = (await data).entries;

        if (response.status == 200) {
            await idbapi.saveToStorage('products', doneData)
            
        } else {
            const localData = await idbapi.checkStorage('products');
            doneData = localData;
        }

        return doneData;
    }

    public async getMainCategories(): Promise<MainCategory[]> {
        const idbapi = new IdbApi();
        const response = await fetch(
            `${this.baseUrl}/collections/get/MainCategories`,
            {
                headers: { 'Cockpit-Token': this.token },
            }
        );

        const data = await response.json() as Promise<MainCategoryResponse>;
        let doneData = (await data).entries;

        if (response.status == 200) {
            await idbapi.saveToStorage('maincategories', doneData);
        } else {
            const localData = await idbapi.checkStorage('maincategories');
            doneData = localData;
        }
        
        return doneData;
    }

    public async getSubCategories(): Promise<SubCategory[]> {
        const idbapi = new IdbApi();
        const response = await fetch(
            `${this.baseUrl}/collections/get/subcategories`,
            {
                headers: { 'Cockpit-Token': this.token },
            }
        );
        
        const data = await response.json() as Promise<SubCategoryResponse>;
        let doneData = (await data).entries;

        if (response.status == 200) {
            await idbapi.saveToStorage('subcategories', doneData);
        } else {
            const localData = await idbapi.checkStorage('subcategories');
            doneData = localData;
        }

        return doneData;
    }
}
