















































































import { Vue } from 'vue-property-decorator';
import Component from 'vue-class-component';
import marked from 'marked';
import { Product, SubCategory } from '@/types';
import { imgUrl } from '@/helpers';
import SkynexHeader from '@/components/skynex-header.vue';

@Component({
    components: {
        SkynexHeader
    },
})
export default class ProductPage extends Vue {
    private isFavorite = false;
    private youtubeEmbed = '';

    private product: Product = {
        _id: '',
        _created: 0,
        _modified: 0,
        title: 'Loading',
    };

    public productSubcat: SubCategory = {
        _id: '',
        _created: 0,
        _modified: 0,
        name: '',
        image: { path: '' },
    };

    public async mounted() {
        const id = this.$f7route.params.productId;
        this.product = await this.$store.getters.getProductByProductId(id);
        this.productSubcat = await this.$store.getters.getSubcatBySubcatId(this.product.subcategory?._id);

        if (this.product.youtube) {
            this.youtubeEmbed =
                'https://www.youtube.com/embed/' + this.product.youtube;
        }

        this.checkIfFavorite();
    }

    public async checkIfFavorite() {
        const favs = this.$store.state.favorites;

        if (favs) {
            const includes = favs.includes(this.product._id);

            if (includes) {
                this.isFavorite = true;
            }
        }
    }

    public setFavorite() {
        if (this.isFavorite) {
            this.isFavorite = false;
            this.$store.commit('removeFavorite', this.product._id);
        } else {
            this.isFavorite = true;
            this.$store.commit('addFavorite', this.product._id);
        }
    }

    public downloadHandout(handoutUrl: string) {
        window.open(handoutUrl, '_blank');
    }

    public get description() {
        if (!this.product.description) return '';
        return marked(this.product.description);
    }

    private imgUrl(path: string): string {
        return imgUrl(path);
    }
}
