







import Vue from 'vue';
import Component from 'vue-class-component';
import routes from '@/routes';


@Component
export default class App extends Vue {
    public f7params = {
        name: 'Skynex Products',
        id: 'ch.bitforge.skynex.products',
        theme: 'md',
        routes: routes,
        view: {
            pushState: true,
        },
    };
}
