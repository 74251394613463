export function isIos() {
    return /iPad|iPod|iPhone/i.test(navigator.userAgent);
}

export function isAndroid() {
    return /android/i.test(navigator.userAgent);
}

export function imgUrl(path: string): string {
    return process.env.VUE_APP_COCKPIT_URL + path;
}
