


































import Vue from 'vue';
import Component from 'vue-class-component';
import { imgUrl } from '@/helpers';
import SkynexHeader from '@/components/skynex-header.vue';
import { MainCategory, SubCategory } from '@/types';

@Component({
    components: {
        SkynexHeader,
    }
})
export default class SubCategoryOverview extends Vue {
    public currentMainCat: MainCategory = {
        _id: '',
        _created: 0,
        _modified: 0,
        title: '',
        big: false,
        image: { path: '' },
    };

    private filteredSubcats: Array<SubCategory> = new Array<SubCategory>();

    public async mounted() {
        const id = this.$f7route.params.maincatId;
        this.currentMainCat = await this.$store.getters.getMaincatById(id);
        this.filteredSubcats = await this.$store.getters.getSubcatByMaincatId(id);
    }

    private get hostUrl() {
        return window.location.protocol + '//' + window.location.host
    }

    private imgUrl(path: string): string {
        return imgUrl(path);
    }
}
