import Vue from 'vue';
import Framework7 from 'framework7/framework7-lite.esm.bundle';
import Framework7Vue from 'framework7-vue/framework7-vue.esm.bundle';
import 'framework7/css/framework7.bundle.css';

import './registerServiceWorker';

import App from '@/app.vue';
import { store } from './store';
import '@/css/app.scss';
import '@/css/icons.css';


Framework7.use(Framework7Vue);

new Vue({
    el: '#app',
    store: store,
    render: (h) => h(App),
    beforeMount: function() {
        this.$store.dispatch('getAllMaincats');
        this.$store.dispatch('getAllSubcats');
        this.$store.dispatch('getAllProducts');
        this.$store.dispatch('getFavorites');
    }
});
