import Products from '@/pages/products.vue';
import AboutPage from '@/pages/about.vue';
import ProductPage from '@/pages/product.vue';
import CategoryOverview from '@/pages/category-overview.vue';
import SubCategoryOverview from '@/pages/subcategory-overview.vue';
import FavoritesPage from '@/pages/favorites.vue';

const routes = [
    /*
    {
        path: '/',
        component: HomePage,
    },
    */
    {
        path: '/',
        component: CategoryOverview,
    },
    {
        path: '/categoryoverview',
        component: CategoryOverview,
    },
    {
        path: '/subcats/:maincatId',
        component: SubCategoryOverview,
    },
    {
        path: '/about',
        component: AboutPage,
    },
    {
        path: '/product/:productId',
        component: ProductPage,
    },
    {
        path: '/products/:subcategoryId',
        component: Products,
    },
    {
        path: '/favorites',
        component: FavoritesPage ,
    }
];
export default routes;
