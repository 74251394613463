















































import Vue from 'vue';
import Component from 'vue-class-component';
import QRCode from 'qrcode';
import { isIos, isAndroid, imgUrl } from '@/helpers';
import SkynexHeader from '@/components/skynex-header.vue';

@Component({
    components: {
        SkynexHeader,
    }
})
export default class CategoryOverview extends Vue {
    public mounted() {
        this.drawQr();
    }

    private imgUrl(path: string): string {
        return imgUrl(path);
    }

    private get isMobile() {
        return isIos() || isAndroid();
    }

    private get hostUrl() {
        return window.location.protocol + '//' + window.location.host;
    }

    private drawQr() {
        QRCode.toCanvas(this.$refs.qrcanvas, this.hostUrl, { width: 300 });
    }
}
