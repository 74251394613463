
import Vue from 'vue';
import Vuex from 'vuex';

import CockpitApi from '@/api';
import '@/css/app.scss';
import '@/css/icons.css';
import { MainCategory, Product, SubCategory } from './types';
import FavoritesApi from './favorites-api';


Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        products: new Array<Product>(),
        favorites: new Array<string>(),
        filteredProducts: new Array<Product>(),
        maincategories: new Array<MainCategory>(),
        subcategories: new Array<SubCategory>(),
        filteredSubcategories: new Array<SubCategory>(),
    },
    getters: {
        getMaincatById: (state) => async (id: string) => {
            if (state.maincategories.length == 0) return null;
            return state.maincategories.find(cat => cat._id == id);
        },
        getSubcatBySubcatId: (state) => async (id: string) => {
            if (state.subcategories.length == 0) return null;
            return state.subcategories.find(subcategory => subcategory._id == id);
        },
        getProductByProductId: (state) => async (id: string) => {
            if (state.products.length == 0) return null;
            return state.products.find(product => product._id == id);
        },
        getFavoriteProducts: (state) => () => {
            if (state.favorites.length == 0) return null;
            return state.products.filter(product => state.favorites.includes(product._id));
        },
        getSubcatByMaincatId: (state) => async (id: string) => {
            if (state.subcategories.length == 0) return null;
            return state.subcategories.filter(subcategory => subcategory.maincategory?._id == id);
        },
        getProductsBySubcatId: (state) => async (id: string) => {
            if (state.products.length == 0) return null;
            return state.products.filter(product => product.subcategory?._id == id);
        },
    },
    mutations: {
        setProducts: (state, products) => {
            state.products = products;
            state.filteredProducts = products;
        },
        setFavorites: (state, favoriteArray) => {
            state.favorites = favoriteArray;
        },
        addFavorite: async (state, favoriteId) => {
            const fApi = new FavoritesApi();
            await fApi.saveFavorite(favoriteId);
            state.favorites = await fApi.getAllFavorites();
        },
        removeFavorite: async (state, favoriteId) => {
            const fApi = new FavoritesApi();
            await fApi.removeFavorite(favoriteId);
            state.favorites = await fApi.getAllFavorites();
        },
        getFavorites: async (state) => {
            const fApi = new FavoritesApi();
            const favorites = await fApi.getAllFavorites();
            state.favorites = favorites;
        },
        setMaincategories: (state, maincategories) => {
            state.maincategories = maincategories;
        },
        setSubcategories: (state, subcategories) => {
            state.subcategories = subcategories;
            state.filteredSubcategories = subcategories;
        },
        saveMaincats: (state, maincats) => {
            state.maincategories = maincats;
        },
        saveSubcats: (state, subcats) => {
            state.subcategories = subcats;
        },
        saveProducts: (state, products) => {
            state.products = products;
        },
    },
    actions: {
        getAllMaincats: async (context) => {
            const api = new CockpitApi();
            try {
                const response = await api.getMainCategories();
                context.commit('saveMaincats', response);
            } catch (err) {
                console.error('Error in fetching getAllMaincats: ' + err);
            }
        },
        getAllSubcats: async (context) => {
            const api = new CockpitApi();
            try {
                const response = await api.getSubCategories();
                context.commit('saveSubcats', response);
            } catch (err) {
                console.error('Error in fetching getAllSubcats: ' + err);
            }
        },
        getAllProducts: async (context) => {
            const api = new CockpitApi();
            try {
                const response = await api.getProducts();
                context.commit('saveProducts', response);
            } catch (err) {
                console.error('Error in fetching products: ' + err);
            }
        },
        getFavorites: async (context) => {
            const fApi = new FavoritesApi();
            const favorites = await fApi.getAllFavorites();
            context.commit('setFavorites', favorites);
        }
    },
});

export { store };