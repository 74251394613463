import { Vue } from 'vue-property-decorator';
import IdbApi from './idb-api';

export default class FavoritesApi extends Vue {

    public async saveFavorite(prodId: string) {
        const idbapi = new IdbApi();
        const id = prodId;

        let finalArray = new Array<string>();

        const newTempArray = new Array<string>();
        newTempArray.push(id);
        finalArray = newTempArray;
        
        const currentFavorites = await this.getAllFavorites();

        if (currentFavorites)
            finalArray = currentFavorites.concat(newTempArray);
        try {
            await idbapi.saveToStorage('favorites', finalArray);
        } catch(e) {
            console.log('Error in saving favorite: ' + e);
        }        
    }

    public async removeFavorite(prodId: string) {
        const idbapi = new IdbApi();
        const id = prodId;

        const currentFavorites = await this.getAllFavorites();
        const finalArray = currentFavorites.filter(e => e !== id);

        try {
            await idbapi.saveToStorage('favorites', finalArray);
        } catch(e) {
            console.log('Error in removing favorite: ' + e);
        }      
    }

    public async getAllFavorites(): Promise<string[]> {
        const idbapi = new IdbApi();
        const localData = await idbapi.checkStorage('favorites') as Array<string>;
        return localData;
    }
}
