


































import Vue from 'vue';
import Component from 'vue-class-component';
import QRCode from 'qrcode';
import { Product, SubCategory } from '@/types';
import { imgUrl } from '@/helpers';
import SkynexHeader from '@/components/skynex-header.vue';

@Component({
    components: {
        SkynexHeader,
    }
})
export default class Products extends Vue {
    private products: Product[] = [];

    public subCat: SubCategory = {
        _id: '',
        _created: 0,
        _modified: 0,
        name: '',
        image: { path: '' },
    };

    public async mounted() {
        const id = this.$f7route.params.subcategoryId;
        this.products = await this.$store.getters.getProductsBySubcatId(id);
        this.subCat = await this.$store.getters.getSubcatBySubcatId(id);
        this.drawQr();
    }

    private imgUrl(path: string): string {
        return imgUrl(path);
    }

    private get hostUrl() {
        return window.location.protocol + '//' + window.location.host
    }

    private drawQr() {
        QRCode.toCanvas(this.$refs.qrcanvas, this.hostUrl, {width: 300});
    }
}
