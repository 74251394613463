

































import Vue from 'vue';
import Component from 'vue-class-component';
import { Product } from '@/types';
import { imgUrl } from '@/helpers';
import SkynexHeader from '@/components/skynex-header.vue';

@Component({
    components: {
        SkynexHeader,
    }
})
export default class FavoritesPage extends Vue {
    private products: Product[] = [];

    private imgUrl(path: string): string {
        return imgUrl(path);
    }

    private get hostUrl() {
        return window.location.protocol + '//' + window.location.host
    }

    public mounted() {
        this.products = this.$store.getters.getFavoriteProducts();
    }
}
