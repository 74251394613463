import { deleteDB, openDB, OpenDBCallbacks } from 'idb';

export default class IdbApi {
    public async dbPromise() {
        if (!('indexedDB' in window)) {
            throw new Error('Browser does not support IndexedDB');
        }

        //deleteDB('MyADSGData'); // todo: remove for live version

        const db = await openDB('MyADSGData', undefined, {
            upgrade(db, oldVersion, newVersion, transaction) {

                if (!db.objectStoreNames.contains('products')) {
                    db.createObjectStore('products')
                }
                if (!db.objectStoreNames.contains('subcategories')) {
                    db.createObjectStore('subcategories')
                }
                if (!db.objectStoreNames.contains('maincategories')) {
                    db.createObjectStore('maincategories')
                }
                if (!db.objectStoreNames.contains('favorites')) {
                    db.createObjectStore('favorites')
                }
            },
            blocked() {
                // …
            },
            blocking() {
                // …
            },
            terminated() {
                // …
            },
        });

        return db;
    }

    public async saveToStorage(storeName: string, data: any) {
        try {
            const db = await this.dbPromise();
            const tx = db.transaction(storeName, 'readwrite');
            const store = tx.objectStore(storeName);
            store.put(data, storeName);
            return tx.oncomplete;
        } catch (error) {
            return console.log('idb-api - saveToStorage: ' + error);
        }
    }

    public async addToStorage(storeName: string, data: any) {
        try {
            console.log('start add to storage');

            const db = await this.dbPromise();
            console.log('after getting db');

            const tx = db.transaction(storeName, 'readwrite');
            console.log('after transaction');

            const store = tx.objectStore(storeName);
            console.log('after objecstore');

            await store.add(data, storeName);
            console.log('after add');

            console.log('end add to storage');
            return tx.oncomplete;
        } catch (error) {
            return console.log('idb-api - addToStorage: ' + error);
        }
    }

    public async checkStorage(storeName: string) {
        try {
            const db = await this.dbPromise();
            const tx = db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            return store.get(storeName);
        } catch (error) {
            return console.log('idb-api - checkStorage: ' + error);
        }
    }

    public async logStorage(storeName: string) {
        try {
            const db = await this.dbPromise();
            const tx = db.transaction(storeName, 'readonly');
            const store = tx.objectStore(storeName);
            console.log(store.get(storeName));
            return store.get(storeName);
        } catch (error) {
            return console.log('idb-api - logStorage: ' + error);
        }
    }
}