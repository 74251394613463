




















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class SkynexHeader extends Vue {
    @Prop({ required: true }) public title!: string;
    @Prop() public titleSecondLine!: string;
    @Prop() public imageString!: string;
    @Prop() public backButton!: boolean;
    @Prop() public smallText!: boolean;
}
